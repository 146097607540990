<template>
  <div>
    <h1>Αθλητές</h1>
  </div>
</template>

<script>
export default {
  name: "Athletes",

  data: () => ({}),

  methods: {},
};
</script>
